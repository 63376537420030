import React, { Component } from 'react';
import { SideElementButton } from '../Button/SideElementButton';

const scrollToTop = () => {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // other
};

export class ScrollButton extends Component {

  render() {

    return (
      <SideElementButton
        name="Scroll button"
        aria-label="Przenieś na górę strony"
        type="button"
        onClick={scrollToTop}
        className={'scroll-button'}
      >
        <i className="fa fa-angle-up mb-1"/>
      </SideElementButton>
    );
  }
}

ScrollButton.defaultProps = {};

ScrollButton.propTypes = {};
