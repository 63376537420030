import * as React from 'react';
import { ReactHTML } from 'react';

const mergeDedupe = (...arrays) => [...new Set([].concat(...arrays))];

const defaultClassNames = ['h-10', 'w-10', 'flex', 'justify-center', 'items-center', 'rounded-full', 'text-2xl', 'text-white', 'side-elements-default'];

export const SideElementButton: React.FC<React.ComponentProps<ReactHTML['button']>> = (props) => {
  const { className, ...rest } = props;

  return (
    <button
      className={mergeDedupe(defaultClassNames, (className || '').split(' ')).join(' ')}
      {...rest}
    />
  );
};
