import React from 'react';
import { SideElementButton } from '../Button/SideElementButton';

export const NewsletterButton = (props) => (
  <SideElementButton
    name="Newsletter button"
    aria-label="Przenieś na górę strony"
    type="button"
    onClick={props.onClick}
    className={'newsletter-button'}
  >
    <i className="fa fa-paper-plane-o text-base mr-1"/>
  </SideElementButton>
);

NewsletterButton.defaultProps = {};

NewsletterButton.propTypes = {};
