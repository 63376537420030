// @ts-check
import React from 'react';
import { Button } from '../Button/Button';
import { CloseIcon } from '../Icons/CloseIcon';
import { StyledInput } from '../StyledInput';

export const Newsletter = (props) => {

  return (
    <div
      className="py-3 px-5 w-64 newsletter fade-in"
      data-newsletter="container"
    >
      <button
        className="close-button"
        onClick={props.onClose}
        name="newsletter close button"
        aria-label="Zamknij formularz"
      >
        <CloseIcon width="20" height="20"/>
      </button>
      <h4
        className="text-lg mb-4 content-color"
      >
        Newsletter
      </h4>
      <p
        className="text-sm mb-3 content-color"
      >
        Nie chcesz, aby coś Cię ominęło? Zapisz się do newslettera.
      </p>
      <form
        action="https://app.getresponse.com/add_subscriber.html"
        acceptCharset="utf-8"
        method="post"
        target="_blank"
        className="font-compatibility"
      >
        <StyledInput
          required
          type="email"
          placeholder="Twój email"
          className={'mb-2 text-sm'}
          name="email"
          aria-label="Pole email do newslettera"
        />
        <input
          type="hidden"
          name="campaign_token"
          value="qR0Li"
        />
        <input type="hidden" name="thankyou_url" value="https://fakturuj.to/podziekowanie-za-zapis-do-newslettera"/>
        <Button
          theme="primary"
          type="submit"
          aria-label="Button wysyłki formularza"
          style={{marginLeft: '0'}}
        >
          ZAPISUJĘ SIĘ
        </Button>
      </form>
    </div>
  );
};

Newsletter.defaultProps = {};

Newsletter.propTypes = {};
