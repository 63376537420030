import React, { useEffect, useState } from 'react';
import { ScrollButton } from '../ScrollButton/ScrollButton';
import { Newsletter } from '../Newsletter/Newsletter';
import { NewsletterButton } from '../Newsletter/NewsletterButton';

const throttle = require('lodash.throttle');

const newsletterToggle = (isNewsletterOpen) => {
  const newsletterForm = document.querySelector('div[data-newsletter="container"]');

  if (isNewsletterOpen) {
    newsletterForm.classList.remove('fade-in');
    newsletterForm.classList.add('fade-out');
  } else {
    newsletterForm.classList.remove('fade-out');
    newsletterForm.classList.add('fade-in');
  }
};

const sideContainerToggle = () => {
  const sideContainer = document.getElementById('sideContainer');

  const toggleFunction = () => {
    if (document.body.scrollTop > 250 || document.documentElement.scrollTop > 250) {
      sideContainer.classList.add('fade-in');
      sideContainer.classList.remove('fade-out');
    } else {
      if (!sideContainer.classList.contains('fade-in')) {
        return;
      }
      sideContainer.classList.add('fade-out');
      sideContainer.classList.remove('fade-in');
    }
  };
  window.addEventListener('scroll', throttle(toggleFunction, 100));
  window.addEventListener('load', throttle(toggleFunction, 0));
};

export const SideElements = () => {
  const [isNewsletterOpen, setIsNewsletterOpen] = useState(false);

  useEffect(sideContainerToggle, []);

  useEffect(() => newsletterToggle(isNewsletterOpen), [isNewsletterOpen]);

  return (
    <div
      className="z-10 side-elements flex"
      id="sideContainer"
    >
      <Newsletter
        onClose={() => setIsNewsletterOpen(!isNewsletterOpen)}
      />
      <NewsletterButton
        onClick={() => setIsNewsletterOpen(!isNewsletterOpen)}
      />
      <ScrollButton/>
    </div>
  );
};

SideElements.defaultProps = {};

SideElements.propTypes = {};
