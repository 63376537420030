import * as React from "react"

export const Footer: React.FC = () => (
  <footer>
    <div className="container mx-auto px-8">
      <div className="w-full flex flex-col md:flex-row py-8 items-center justify-center content-color">
        <div
          style={{ flexWrap: "wrap" }}
        >
          <a href="/regulamin">
            REGULAMIN
          </a>
          &nbsp;|&nbsp;
          <a href="/polityka-prywatnosci">
            POLITYKA&nbsp;PRYWATNOŚCI
          </a>
          &nbsp;
          <a href="/standardy-bezpieczenstwa">
            | STANDARDY&nbsp;BEZPIECZEŃSTWA
          </a>
          &nbsp;
          <a href="/filmy-wprowadzajace">
            | FILMY&nbsp;WPROWADZAJĄCE
          </a>
          &nbsp;
          <a href="/dofinansowanie">
            | DOFINANSOWANIE
          </a>
        </div>
      </div>
    </div>
  </footer>
)
